import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';

const dayjsType = z.custom<Dayjs>((data) => dayjs.isDayjs(data), {
  message: 'Expected an instance of dayjs',
});

export const DownloadArchiveFormSchema = z.object({
  range: z
    .array(dayjsType)
    .length(2, { message: 'Array must contain exactly 2 elements' }),
  imei: z.string(),
});

export type DownloadArchiveFormSchemaType = z.infer<
  typeof DownloadArchiveFormSchema
>;
