import { GroupOutput, ParkOutput } from '@shared/api/services/common/api';

export const getParkNameByGroupId = (
  groupId: number,
  parks: ParkOutput[]
): string => {
  const park = parks.find((park) =>
    park.groups?.find((el) => el.id === groupId)
  );

  if (park) {
    return park.name;
  }

  return '';
};

// ПОЛЕЗНО
