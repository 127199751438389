import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  ElectricBusSortEnum,
  SortDirection,
} from '@shared/api/services/electricbus/api';
import { useLazyElectricbusesQuery } from '@shared/api/services/electricbus/enhanced';
import {
  useLazyGetBatteryTelemetry,
  useLazyGetTelemetryQuery,
} from '@shared/api/services/telemetry/enhanced';
import { QUERY_PARAM } from '@shared/consts/server';
import { countPageSize } from '@shared/ui/table/lib/count-page-size';
import {
  BatteryTelemetryErrorBaseOutput,
  BatteryTelemetryWithErrorsOutput,
} from '@shared/api/services/telemetry/api';
import { BatteriesTelemetryData, VehiclesTelemetryData } from '../consts/types';
import { useGetGroupsQuery } from '@shared/api/services/common/enhanced';

export const useData = () => {
  const {
    data: parks = [],
    isLoading: isGroupsLoading,
    error: groupsError,
    isSuccess: isGroupsSuccess,
  } = useGetGroupsQuery();

  const [
    getElectrobuses,
    {
      currentData: electricbuses,
      isLoading: isElectricbusesLoading,
      error: electricbusesError,
      isSuccess: isElectricbusesSuccess,
      isFetching: isElectricbusesFetching,
    },
  ] = useLazyElectricbusesQuery({ pollingInterval: 5000 });

  const [
    getTelemetry,
    {
      currentData: telemetry,
      isLoading: isTelemetryLoading,
      isFetching: isTelemetryFetching,
      error: isTelemetryError,
      isSuccess: isTelemetrySuccess,
    },
  ] = useLazyGetTelemetryQuery({ pollingInterval: 5000 });

  const [
    getBatteriesTelemetry,
    {
      currentData: batteriesTelemetry,
      isLoading: isBatteriesTelemetryLoading,
      isFetching: isBatteriesTelemetryFetching,
      error: isBatteriesTelemetryError,
      isSuccess: isBatteriesTelemetrySuccess,
    },
  ] = useLazyGetBatteryTelemetry({ pollingInterval: 5000 });

  useEffect(() => {
    const fetchAllData = async () => {
      await getElectrobuses({
        size: 100,
      });
      await getTelemetry({});
      await getBatteriesTelemetry({ perPage: 300 });
    };
    fetchAllData();
  }, []);

  const vehiclesTelemetryTableData: VehiclesTelemetryData = useMemo(() => {
    const ebusesData = electricbuses?.items;

    if (
      ebusesData === undefined ||
      ebusesData === null ||
      telemetry === undefined ||
      telemetry === null ||
      batteriesTelemetry === undefined ||
      batteriesTelemetry === null
    ) {
      return [];
    }

    return ebusesData
      .filter((electrobusItem) => electrobusItem.tracker?.imei !== undefined)
      .map((electrobusItem) => {
        const trackerImei = electrobusItem.tracker!.imei;
        const currentTelemetry = telemetry.find(
          ({ tracker_imei }) => tracker_imei === trackerImei
        );

        const batteries =
          batteriesTelemetry.filter((battery) => {
            return battery.telemetry_id === currentTelemetry?.id;
          }) ?? [];

        const errors = batteries.reduce((allErrors, { errors }) => {
          const processedErrors = errors ?? [];
          return [...allErrors, ...processedErrors];
        }, [] as BatteryTelemetryErrorBaseOutput[]);

        const park_name = parks.find(({ groups }) =>
          groups.some((group) => group.id === electrobusItem.group_id)
        )?.name;

        return {
          park_name: park_name,
          imei: trackerImei,
          grz: electrobusItem.grn,
          garage_number: electrobusItem.garage_number,
          time: currentTelemetry?.time,
          coordinates: {
            latitude: currentTelemetry?.latitude,
            longitude: currentTelemetry?.longitude,
          },
          firmware: electrobusItem.tracker!.firmware,
          soc: currentTelemetry?.soc,
          soh: currentTelemetry?.soh,
          charge_current_A: currentTelemetry?.charge_current_A,
          discharge_current_A: currentTelemetry?.discharge_current_A,
          permissible_charge_current:
            currentTelemetry?.permissible_charge_current,
          permissible_discharge_current:
            currentTelemetry?.permissible_discharge_current,
          power_reserve: currentTelemetry?.power_reserve,
          last_charge: currentTelemetry?.last_charge,
          ctts: currentTelemetry?.ctts,
          interval_telemetry_S: currentTelemetry?.interval_telemetry_S,
          count_charge_session: currentTelemetry?.count_charge_session,
          errors,
          batteries,
        };
      })
      .sort((a, b) => Number(a.imei) - Number(b.imei));
  }, [electricbuses, telemetry, batteriesTelemetry]);

  const batteriesTelemetryTableData = useMemo(() => {
    const ebusesData = electricbuses?.items;

    if (
      ebusesData === undefined ||
      ebusesData === null ||
      telemetry === undefined ||
      telemetry === null ||
      batteriesTelemetry === undefined ||
      batteriesTelemetry === null
    ) {
      return [];
    }

    const result = vehiclesTelemetryTableData.reduce(
      (batteriesData, vehicle) => {
        if (vehicle.batteries.length === 0) {
          return batteriesData;
        } else {
          const preparedBatteries = vehicle.batteries.map((battery) => ({
            imei: vehicle.imei,
            grz: vehicle.grz,
            garage_number: vehicle.garage_number,
            ...battery,
          }));
          return [...batteriesData, ...preparedBatteries];
        }
      },
      [] as BatteriesTelemetryData
    );

    return result;
  }, [vehiclesTelemetryTableData, telemetry, batteriesTelemetry]);

  const error = [
    electricbusesError,
    isTelemetryError,
    isBatteriesTelemetryError,
    groupsError,
  ].find((err) => err !== undefined);

  const isLoading =
    isElectricbusesLoading ||
    isTelemetryLoading ||
    isBatteriesTelemetryLoading ||
    isGroupsLoading;

  const isSuccess =
    isElectricbusesSuccess &&
    isTelemetrySuccess &&
    isBatteriesTelemetrySuccess &&
    isGroupsSuccess;

  return {
    vehiclesTelemetryTableData,
    batteriesTelemetryTableData,
    isLoading,
    error,
    isSuccess,
    //  defaultPageSize,
  };
};
