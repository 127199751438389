import { enhancedApi as api } from '../api';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getParksApiParksGet: (endpoint) => {
      endpoint.query = () => `common/api/parks`;
      endpoint.providesTags = ['VehicleGroups'];
    },
    getManufacturesApiVehiclesManufacturesGet: (endpoint) => {
      endpoint.query = () => `common/api/vehicles/manufactures`;
      endpoint.providesTags = ['VehicleManufacturers'];
    },
    getStatusesApiVehiclesTypesGet: (endpoint) => {
      endpoint.query = () => `common/api/vehicles/types`;
      endpoint.providesTags = ['VehicleTypes'];
    },
    getManufacturesApiVehiclesModelsGet: (endpoint) => {
      endpoint.query = () => `common/api/vehicles/models`;
      endpoint.providesTags = ['VehicleModels'];
    },
    getStatusesApiVehiclesStatusesGet: (endpoint) => {
      endpoint.query = () => `common/api/vehicles/statuses`;
      endpoint.providesTags = ['VehicleStatuses'];
    },
    getGroupsApiTrackersModelsGet: (endpoint) => {
      endpoint.query = () => `common/api/trackers/models`;
      endpoint.providesTags = ['TrackerModels'];
    },
    getCounterpartyInfoApiCounterpartiesGet: (endpoint) => {
      endpoint.query = () => `common/api/counterparties`;
      endpoint.providesTags = ['Counterparties'];
    },
    getCounterpartyInfoApiCounterpartyGet: (endpoint) => {
      endpoint.query = () => `common/api/counterparty`;
      endpoint.providesTags = ['Counterparty'];
    },
  },
});

export const {
  useGetParksApiParksGetQuery: useGetGroupsQuery,
  useLazyGetCounterpartyInfoApiCounterpartyGetQuery:
    useLazyGetCounterpartyQuery,
  useGetManufacturesApiVehiclesManufacturesGetQuery: useGetManufacturersQuery,
  useGetStatusesApiVehiclesTypesGetQuery: useGetVehicleTypesQuery,
  useGetManufacturesApiVehiclesModelsGetQuery: useGetVehicleModelsQuery,
  useGetStatusesApiVehiclesStatusesGetQuery: useGetVehicleStatusesQuery,
  useGetGroupsApiTrackersModelsGetQuery: useGetTrackersModelsQuery,
  useLazyGetGroupsApiTrackersModelsGetQuery: useLazyGetTrackersModelsQuery,
  useGetCounterpartyInfoApiCounterpartiesGetQuery: useGetCounterpartiesQuery,
  useLazyGetCounterpartyInfoApiCounterpartiesGetQuery:
    useLazyGetCounterpartiesQuery,
} = enhancedApi;
