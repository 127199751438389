import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { UiModal } from '@shared/ui/ui-modal';
import { DownloadArchiveForm } from '../download-form';
import { useLazyGetReportTelemetryQuery } from '@shared/api/services/telemetry/enhanced';
import { DownloadArchiveFormSchemaType } from '../../consts';
import dayjs from 'dayjs';
import {
  openErrorNotification,
  openSuccessNotification,
} from '@shared/lib/notification';
import { useEffect, useState } from 'react';
import { Spin } from 'antd';

type Props = {
  imei: string;
};

export const DownloadArchiveModal = NiceModal.create(({ imei }: Props) => {
  const modal = useModal();
  const [downloadArchive, { data, isLoading }] =
    useLazyGetReportTelemetryQuery();

  const onSubmit = async (data: DownloadArchiveFormSchemaType) => {
    const [dateFrom, dateTo] = data.range;
    const trackerImei = data.imei;

    const formatterDateFrom = dateFrom.format('YYYY-MM-DD');
    const formatterDateTo = dateTo.format('YYYY-MM-DD');
    try {
      const res = await downloadArchive({
        dateFrom: formatterDateFrom,
        dateTo: formatterDateTo,
        imei: trackerImei,
      });

      if (res.data) {
        const url = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Отчёт за период с ${formatterDateFrom} по ${formatterDateTo}.xls`
        ); // Задаем имя сохраняемого файла
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Удаляем ссылку после скачивания
        window.URL.revokeObjectURL(url); // Освобождаем URL после использования

        openSuccessNotification(`Телеметрия успешно загрузилась!`);
      } else {
        throw Error();
      }
    } catch (e) {
      openErrorNotification(`Не получилось загрузить телеметрию`);
    } finally {
      modal.hide();
    }
  };
  console.log('isLoading', isLoading);

  return (
    <UiModal isOpen={modal.visible} onClose={isLoading ? () => {} : modal.hide}>
      <Spin spinning={isLoading}>
        <UiModal.Header onClose={isLoading ? () => {} : modal.hide}>
          Загрузить архивные данные
        </UiModal.Header>
        <UiModal.Body>
          <DownloadArchiveForm
            imei={imei}
            closeModal={modal.hide}
            onSubmit={onSubmit}
            isLoading={isLoading}
          />
        </UiModal.Body>
      </Spin>
      {/* <UiModal.Footer>
          <DeleteVehicleButton vehicle={vehicle} />
          <UiButton variant="outline">Сбросить</UiButton>
          <UiButton variant="primary">Сохранить</UiButton>
        </UiModal.Footer> */}
    </UiModal>
  );
});
