import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';

import {
  selectVehicleFilters,
  setFilters,
} from '@shared/redux/slices/vehicle/filters';

import { useGetHotStorageTelemetryQuery } from '@shared/api/services/telemetry/enhanced';
import { useGetGroupsQuery } from '@shared/api/services/common/enhanced';
import { Telemetry } from '@entities/vehicle/model/telemetry';

import { ParkOfElectrobusesHeader } from '@widgets/header/ui/park-of-electrobuses-header';
import { Map } from '@widgets/vehicle/vehicles-map/map';
import { VehicleInfo } from '@widgets/vehicle/vehicle-info';

import { MapContainer, StyledMonitoringLayout } from './styles';
import { useAppSelector } from '@app/hooks';
import { useDispatch } from 'react-redux';
import { VehicleTableType } from '@entities/vehicle/model/vehicle-table-type';
import { preprocessGroupIdsArg } from '@entities/vehicle/lib/preprocess-group-ids-arg';
import { StandardPageLayout } from '@pages/styles';
import { ElectrobusExpandedInfoPageHeader } from '@widgets/header/ui/electrobus-expanded-info-page';
import { VehicleInfoExpanded } from '@widgets/vehicle/vehicle-info-expanded';
import { TRANSPORT } from '@shared/consts/routes';
import { getWebSocketURL } from '@shared/lib/web-socket/get-web-socket-url';
import { selectAccessToken } from '@shared/redux/slices/auth/storage-slice';
import { useGetUsersInfoQuery } from '@shared/api/services/user/enhanced';
import { useGetElectricbusesQuery } from '@shared/api/services/electricbus/enhanced';

const SOCKET_URL = getWebSocketURL();

export function ElectrobusInfoPage() {
  const accessToken = useAppSelector(selectAccessToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mapType, setMapType] = useState<VehicleTableType | null>(null);
  const [telemetry, setTelemetry] = useState<Record<string, Telemetry>>({});

  const { eParkId, convoyId, eBusId } = useParams();

  const filters = useAppSelector(selectVehicleFilters);

  const {
    groupIds,
    manufactureIds,
    modelsIds,
    statusEvIds,
    typeId,
    sohFrom,
    sohTo,
    socFrom,
    socTo,
    powerReserveFrom,
    powerReserveTo,
    mileageFrom,
    mileageTo,
  } = filters;

  // Добавить уведомление об обсутствии данных в редисе
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isSuccess: isUserDataSuccess,
    error: userDataError,
  } = useGetUsersInfoQuery();

  const { data, isLoading, isFetching, error } = useGetHotStorageTelemetryQuery(
    {
      parkId: eParkId!,
      modelIds: modelsIds.length > 0 ? modelsIds.join(',') : undefined,
      typeId: typeId ?? undefined,
      manufactureIds:
        manufactureIds.length > 0 ? manufactureIds.join(',') : undefined,
      statusIds: statusEvIds.length > 0 ? statusEvIds.join(',') : undefined,
      socFrom: socFrom ?? undefined,
      socTo: socTo ?? undefined,
      sohFrom: sohFrom ?? undefined,
      sohTo: sohTo ?? undefined,
      evId: eBusId,
      groupIds: groupIds !== undefined ? String(groupIds) : undefined,
    }
  );

  const {
    data: parks,
    isLoading: isGroupsLoading,
    error: groupsError,
    isSuccess: isGroupsSuccess,
  } = useGetGroupsQuery();

  const {
    data: electrobusesData,
    isLoading: isElectrobusesDataLoading,
    error: electrobusesDataError,
    isSuccess: isElectrobusesDataSuccess,
  } = useGetElectricbusesQuery({ size: 100 });

  useEffect(() => {
    if (eParkId && convoyId && eBusId) {
      setMapType('ELECTROBUS');
      return;
    }
    if (eParkId && convoyId && !eBusId) {
      setMapType('CONVOY');
      return;
    }
    if (eParkId && !convoyId && !eBusId) {
      setMapType('PARK');
      return;
    }
  }, [eParkId, convoyId, eBusId]);

  useEffect(() => {
    if (mapType) {
      dispatch(
        setFilters({
          ...filters,
          groupIds: preprocessGroupIdsArg(
            mapType,
            groupIds,
            eParkId,
            convoyId,
            parks
          ),
          parkId: Number(eParkId),
        })
      );
    }
  }, [mapType, eParkId, convoyId, eBusId]);

  const { lastMessage, lastJsonMessage, readyState, sendJsonMessage } =
    useWebSocket(SOCKET_URL);

  const handleSubscribe = useCallback(() => {
    if (userData?.username !== undefined) {
      sendJsonMessage({
        type: 'subscription',
        payload: {
          username: userData?.username,
          park_id: eParkId,
          token: accessToken,
        },
      });
    }
  }, [groupIds, userData?.username]);

  useEffect(() => {
    if (userData?.username !== undefined) {
      sendJsonMessage({
        type: 'subscription',
        payload: {
          username: userData.username,
          park_id: eParkId,
          token: accessToken,
        },
      });
    }
  }, [eParkId, groupIds, userData?.username]);

  useEffect(() => {
    if (data) {
      const latestTelemetry = data.reduce((acc, el) => {
        return { ...acc, [el.tracker_imei]: el };
      }, {} as Record<string, Telemetry>);

      setTelemetry(latestTelemetry);
    }
  }, [data]);

  useEffect(() => {
    if (readyState === 1 && data && userData?.username) {
      handleSubscribe();
    }
  }, [readyState, userData?.username]);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const message = lastJsonMessage as Telemetry;

      if (
        message.tracker_imei &&
        data?.some(({ tracker_imei }) => tracker_imei === message.tracker_imei)
      ) {
        setTelemetry((prev) => {
          return { ...prev, [message.tracker_imei]: message };
        });
      }
    }
  }, [lastMessage, data]);

  const [selectedTrackerImei, setSelectedTrackerImei] = useState('');

  const handleSelectVehicle = (trackerImei: string) =>
    setSelectedTrackerImei(trackerImei);
  const handleDeseleceVehicle = () => setSelectedTrackerImei('');

  const isStillLoading =
    isLoading || isGroupsLoading || isElectrobusesDataLoading;

  useEffect(() => {
    if (selectedTrackerImei.length > 0) {
      navigate(`${TRANSPORT}/${eParkId}/${convoyId}/${eBusId}/details`);
    }
  }, [selectedTrackerImei]);

  const currentEbus = electrobusesData?.items.find(({ id }) => id === eBusId);
  const targetGrn = currentEbus?.grn;
  const trackerImei = data?.find(
    ({ ev }) => ev?.grn === targetGrn
  )?.tracker_imei;

  return (
    <StandardPageLayout>
      <ElectrobusExpandedInfoPageHeader />
      {isStillLoading && <>Загрузка данных</>}
      {trackerImei && !isStillLoading && (
        <VehicleInfoExpanded trackerTelemetry={telemetry[trackerImei]} />
      )}
      {!trackerImei && !isStillLoading && (
        <div
          style={{
            marginTop: '75px',
            border: '1px solid grey',
            width: '50%',
            padding: '20px',
          }}
        >
          Не удалось получить телеметрию по транспорту
        </div>
      )}
    </StandardPageLayout>
  );
}
