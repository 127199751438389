import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { TrackerOutput } from '@shared/api/services/tracker/api';
import { getTableSettingsColumn } from '@shared/ui/table/settings-column/get-table-settings-column';
import { TrackerStatusBadge } from '@entities/vehicle/ui/tracker-status-badge';
import { GetGroupsApiTrackersModelsGetApiResponse } from '@shared/api/services/common/api';
import { EditTrackerButton } from '@features/trackers/edit/ui/button';
import { HeaderCheckbox, SelectTrackerCheckbox } from './styles';
import dayjs from 'dayjs';

const columnHelper = createColumnHelper<TrackerOutput>();

type Props = {
  models?: GetGroupsApiTrackersModelsGetApiResponse;
};

export const useColumns = ({ models }: Props) => {
  const [settings, setSettings] = useState<Record<string, boolean>>({
    imei: true,
    park: false,
    phone: false,
    model_id: true,
    firmware: true,
    status: true,
    last_online: true,
  });

  // @ts-ignore
  const selectTrackerColumn = columnHelper.accessor('', {
    id: 'select_tracker',
    size: 20,
    maxSize: 20,
    minSize: 20,
    header: ({ table }) => {
      return (
        <HeaderCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      );
    },
    cell: ({ row }) => {
      return (
        <SelectTrackerCheckbox
          disabled={!row.getCanSelect()}
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      );
    },
  });

  const DATA_COLUMNS = [
    columnHelper.accessor('imei', {
      id: 'imei',
      header: 'ID трекера',
      size: 135,
      maxSize: 135,
      minSize: 135,
      cell: (props) => {
        const stationId = props.getValue();
        return stationId ?? 'Нет данных';
      },
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: 'Статус',
      size: 170,
      maxSize: 170,
      minSize: 170,
      cell: (props) => {
        const val = props.getValue();

        if (val === 'ONLINE' || val === 'OFFLINE') {
          return <TrackerStatusBadge status={val} />;
        }

        return '';
      },
      enableSorting: false,
    }),
    // @ts-ignore
    columnHelper.accessor('phone_number', {
      id: 'phone',
      header: 'Телефон', // выяснить у Володи группу
      size: 100,
      maxSize: 100,
      minSize: 100,
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      enableSorting: false,
    }),
    columnHelper.accessor('last_online', {
      id: 'last_online',
      header: 'Был в сети',
      size: 90,
      maxSize: 90,
      minSize: 90,
      cell: (props) => {
        const value = props.getValue();

        if (value === undefined || value === null) {
          return 'Нет данных';
        }

        const formattedDate = value?.includes('Z') ? value : `${value}Z`;
        const [date, time] = dayjs(formattedDate)
          .format('YYYY.MM.DD HH:mm')
          .split(' ');

        return `${time},  ${date}`;
      },
      enableSorting: true,
    }),
    // @ts-ignore
    columnHelper.accessor('', {
      id: 'park',
      header: 'Парк',
      size: 120,
      maxSize: 120,
      minSize: 120,
      cell: (props) => {
        const name = props.getValue();
        return name ?? 'Нет данных';
      },
    }),
    columnHelper.accessor('model_id', {
      id: 'model_id',
      header: 'Модель', // выяснить у Володи группу
      size: 90,
      maxSize: 90,
      minSize: 90,
      cell: (props) => {
        const modelId = props.getValue();
        const modelName = models?.find(({ id }) => id === modelId)?.name;

        return modelName ?? modelId ?? 'Нет данных';
      },
      enableSorting: true,
    }),
    columnHelper.accessor('firmware', {
      id: 'firmware',
      header: 'Прошивка',
      size: 90,
      maxSize: 90,
      minSize: 208,
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      enableSorting: true,
    }),
  ];

  const settingsColumn = getTableSettingsColumn({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      const typedId = id!;
      return {
        key: id as string,
        label: header as string,
        isChecked: settings[typedId],
      };
    }),
    settings,
    renderCell: (props) => {
      return <EditTrackerButton tracker={props.row.original} />;
    },
    setVisibleColumns: (cols: Record<string, boolean>) => {
      setSettings(cols);
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter(({ id }) => {
          const typedId = id!;
          return settings[typedId];
        })
      : DATA_COLUMNS;

    return [selectTrackerColumn, ...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
