import { GetParksApiParksGetApiResponse } from '@shared/api/services/common/api';
import { VehicleTableType } from '../model/vehicle-table-type';

export const preprocessGroupIdsArg = (
  tableType: VehicleTableType,
  groupIds: number[],
  eParkId?: string,
  convoyId?: string,
  parks?: GetParksApiParksGetApiResponse
) => {
  if (parks === undefined) return;

  if (tableType === 'TRANSPORT') {
    return [];
  }

  if (tableType === 'PARK') {
    const groupIdsInParticularPark =
      parks
        .find(({ id }) => String(id) === eParkId)
        ?.groups?.map(({ id }) => id) ?? [];

    return groupIdsInParticularPark.length > 0
      ? groupIdsInParticularPark
      : undefined;
  }

  if (tableType === 'CONVOY') {
    return convoyId ? [Number(convoyId)] : undefined;
  }
};
