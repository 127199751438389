import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';

import {
  useGetGroupsQuery,
  useGetManufacturersQuery,
  useGetVehicleModelsQuery,
  useGetVehicleStatusesQuery,
  useGetVehicleTypesQuery,
} from '@shared/api/services/common/enhanced';
import {
  useGetElectricbusesQuery,
  useLazyElectricbusesQuery,
} from '@shared/api/services/electricbus/enhanced';
import { QUERY_PARAM } from '@shared/consts/server';
import {
  ElectricBusSortEnum,
  SortDirection,
} from '@shared/api/services/electricbus/api';
import {
  selectVehicleFilters,
  setFilters,
} from '@shared/redux/slices/vehicle/filters';

import { useAppSelector } from '@app/hooks';

import { VehiclesListTableInstance } from '../table';
import { useDispatch } from 'react-redux';
import { VehicleTableType } from '@entities/vehicle/model/vehicle-table-type';
import { preprocessGroupIdsArg } from '@entities/vehicle/lib/preprocess-group-ids-arg';
import { countPageSize } from '@shared/ui/table/lib/count-page-size';

type Props = {
  tableType: VehicleTableType;
};

export const ConnectedVehiclesTable: FC<Props> = ({ tableType }) => {
  const [isPaginatingOrFiltration, setIsFiltratingOrPagination] =
    useState(false);

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { eParkId, convoyId } = useParams();

  const {
    data: parks,
    isLoading: isGroupsLoading,
    error: groupsError,
    isSuccess: isGroupsSuccess,
  } = useGetGroupsQuery();

  const filters = useAppSelector(selectVehicleFilters);

  const {
    groupIds,
    manufactureIds,
    modelsIds,
    statusEvIds,
    typeId,
    sohFrom,
    sohTo,
    socFrom,
    socTo,
    powerReserveFrom,
    powerReserveTo,
    mileageFrom,
    mileageTo,
  } = filters;

  useEffect(() => {
    if (tableType === 'TRANSPORT') {
      dispatch(
        setFilters({
          ...filters,
          groupIds: preprocessGroupIdsArg(
            'TRANSPORT',
            groupIds,
            eParkId,
            convoyId,
            parks
          ),
          parkId: undefined,
        })
      );
    }
    if (tableType === 'PARK') {
      dispatch(
        setFilters({
          ...filters,
          groupIds: preprocessGroupIdsArg(
            'PARK',
            groupIds,
            eParkId,
            convoyId,
            parks
          ),
          parkId: Number(eParkId),
        })
      );
    }
    if (tableType === 'CONVOY') {
      dispatch(
        setFilters({
          ...filters,
          groupIds: preprocessGroupIdsArg(
            'CONVOY',
            groupIds,
            eParkId,
            convoyId,
            parks
          ),
          parkId: Number(eParkId),
        })
      );
    }
  }, [tableType, eParkId, convoyId, parks]);

  const page = searchParams.get(QUERY_PARAM.page);
  const size = searchParams.get(QUERY_PARAM.limit);
  const ordering = searchParams.get(
    QUERY_PARAM.ordering
  ) as ElectricBusSortEnum | null;
  const type = searchParams.get(QUERY_PARAM.type) as SortDirection | null;

  const defaultPageSize = countPageSize();

  const [
    getElectrobuses,
    {
      data: electricbuses,
      isLoading: isElectricbusesLoading,
      error: electricbusesError,
      isSuccess,
      isFetching: isElectricbusesFetching,
    },
  ] = useLazyElectricbusesQuery({ pollingInterval: 5000 });

  useEffect(() => {
    setIsFiltratingOrPagination(true);

    getElectrobuses({
      groupIds: groupIds,
      page: page ? Number(page) : undefined,
      size: size ? Number(size) : defaultPageSize,
      sortedField: ordering ?? undefined,
      sortedBy: type ?? undefined,
      manufactureIds: manufactureIds.length > 0 ? manufactureIds : undefined,
      modelIds: modelsIds.length > 0 ? modelsIds : undefined,
      statusEvIds: statusEvIds.length > 0 ? statusEvIds : undefined,
      typeId: typeId ?? undefined,
      sohFrom,
      sohTo,
      socFrom,
      socTo,
      mileageFrom,
      mileageTo,
      powerReserveFrom,
      powerReserveTo,
    })
      .unwrap()
      .finally(() => {
        setIsFiltratingOrPagination(false);
      });
  }, [
    groupIds,
    page,
    size,
    ordering,
    type,
    manufactureIds,
    modelsIds,
    statusEvIds,
    typeId,
    sohFrom,
    sohTo,
    socFrom,
    socTo,
    mileageFrom,
    mileageTo,
    powerReserveFrom,
    powerReserveTo,
  ]);

  const {
    data: manufacturers,
    isLoading: isManufacturersLoading,
    error: manufacturersError,
    isSuccess: isManufacturersSuccess,
  } = useGetManufacturersQuery();

  const {
    data: vehicleTypes,
    isLoading: isVehicleTypesLoading,
    error: vehicleTypesError,
    isSuccess: isVehicleTypesSuccess,
  } = useGetVehicleTypesQuery();

  const {
    data: vehicleModels,
    isLoading: isVehicleModelsLoading,
    error: vehicleModelsError,
    isSuccess: isVehicleModelsSuccess,
  } = useGetVehicleModelsQuery();

  const {
    data: vehicleStatuses,
    isLoading: isVehicleStatusesLoading,
    error: vehicleStatusesError,
    isSuccess: isVehicleStatusesSuccess,
  } = useGetVehicleStatusesQuery();

  const tableData = useMemo(() => {
    if (!electricbuses) {
      return [];
    }

    return electricbuses.items;
  }, [electricbuses]);

  if (
    isElectricbusesLoading ||
    isManufacturersLoading ||
    isVehicleModelsLoading ||
    isVehicleTypesLoading ||
    isVehicleStatusesLoading ||
    isGroupsLoading
  ) {
    return <div>Идет загрузка....</div>;
  }

  const error = [
    electricbusesError,
    manufacturersError,
    vehicleTypesError,
    vehicleModelsError,
    vehicleStatusesError,
    groupsError,
  ].find((err) => err !== undefined);

  if (error) {
    return <div>Ошибка</div>;
  }

  if (!electricbuses) {
    return <div>Нет данных</div>;
  }

  if (
    isSuccess &&
    isVehicleTypesSuccess &&
    isVehicleModelsSuccess &&
    isManufacturersSuccess &&
    isVehicleStatusesSuccess &&
    isGroupsSuccess
  ) {
    return (
      <VehiclesListTableInstance
        defaultPageSize={defaultPageSize}
        types={vehicleTypes}
        manufacturers={manufacturers}
        models={vehicleModels}
        loading={isPaginatingOrFiltration && !isElectricbusesLoading}
        count={electricbuses.total}
        tableData={tableData}
        statuses={vehicleStatuses}
        parks={parks}
        tableType={tableType}
      />
    );
  }

  return null;
};
