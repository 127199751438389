import { PathMatch } from 'react-router-dom';

import { useGetGroupsQuery } from '@shared/api/services/common/enhanced';
import { useGetElectricbusesQuery } from '@shared/api/services/electricbus/enhanced';

type Props = {
  match: PathMatch<string> | null;
};

export const useVehicleData = ({ match }: Props) => {
  const {
    data: groupsData,
    isLoading: isGroupsLoading,
    error: isGroupsError,
    isSuccess: isGroupsSuccess,
  } = useGetGroupsQuery(undefined, {
    skip: !match,
  });

  const {
    data: electricbusesData,
    isLoading: isElectricbusesLoading,
    error: isElectricbusesError,
    isSuccess: isElectricbusesSuccess,
  } = useGetElectricbusesQuery({ size: 100 });

  const isLoading = isGroupsLoading || isElectricbusesLoading;
  const isSuccess = isElectricbusesSuccess && isGroupsSuccess;

  return {
    groups: groupsData ?? [],
    electricbuses: electricbusesData?.items ?? [],
    isLoading,
    isSuccess,
  };
};
