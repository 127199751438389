import {
  useGetGroupsQuery,
  useLazyGetCounterpartiesQuery,
  useLazyGetCounterpartyQuery,
} from '@shared/api/services/common/enhanced';
import { UserBaseInfoRead } from '@shared/api/services/user/api';
import { useGetUsersInfoQuery } from '@shared/api/services/user/enhanced';
import { FC, useEffect } from 'react';
import {
  EditingFormSchemaType,
  CreatingFormSchemaType,
} from '@entities/user/consts/schemas';

import { UserForm } from '../user-form';

type Props = {
  //  submitButtonLabel: string;
  handleFormSubmit: (data: Partial<CreatingFormSchemaType>) => void;
  loading: boolean;
  formType: 'creating' | 'editing';
  user?: UserBaseInfoRead;
  deleteButton?: JSX.Element;
  isProfile: boolean;
};

export const ConnectedUserForm: FC<Props> = ({
  user,
  deleteButton,
  formType,
  loading,
  isProfile,
  handleFormSubmit,
}) => {
  const {
    data: profile,
    isLoading: isProfileLoading,
    isSuccess: isProfileSuccess,
    error: profileError,
  } = useGetUsersInfoQuery();

  const {
    data: parks,
    isLoading: isParksLoading,
    error: parksError,
    isSuccess: isParksSuccess,
  } = useGetGroupsQuery();

  const [
    getCounterparties,
    {
      data: counterparties,
      isLoading: isCounterpartiesLoading,
      error: counterpartiesError,
      isSuccess: isCounterpartiesSuccess,
    },
  ] = useLazyGetCounterpartiesQuery();

  const [
    getCounterparty,
    {
      data: counterparty,
      isLoading: isCounterpartyLoading,
      error: counterpartyError,
      isSuccess: isCounterpartySuccess,
    },
  ] = useLazyGetCounterpartyQuery();

  useEffect(() => {
    if (profile?.is_staff === undefined || profile?.is_staff === null) return;

    if (profile.is_staff) {
      getCounterparties();
    } else {
      getCounterparty();
    }
  }, [profile?.is_staff]);

  if (
    isParksLoading ||
    isProfileLoading ||
    isCounterpartyLoading ||
    isCounterpartiesLoading
  ) {
    return <>Загрузка...</>;
  }

  const error = [
    profileError,
    parksError,
    counterpartiesError,
    counterpartyError,
  ].find((err) => err !== undefined);

  if (error) {
    return (
      <>
        <p>Ошибка: не удалось загрузить данные для формы</p>
        <p>{JSON.stringify(error)}</p>
      </>
    );
  }

  const getInitialValues = (
    user?: UserBaseInfoRead | null
  ): UserBaseInfoRead | undefined => {
    if (user === undefined || user === null) return;

    return {
      username: user.username,
      last_name: user.last_name,
      available_park_ids: user.available_park_ids,
      first_name: user.first_name,
      surname: user.surname,
      role_id: user.role_id,
      counterparty_id: user.counterparty_id,
      is_staff: user.is_staff,
    };
  };

  /*
  Здесь мы получим необходимые данные для компонента объединённой формы пользователя.
  См пятничные заметки в Notes касательно формы
  */
  return (
    <UserForm
      formType={formType}
      handleFormSubmit={handleFormSubmit}
      loading={loading}
      initialValues={getInitialValues(user)}
      parks={parks ?? []}
      profile={profile!}
      counterparties={counterparties}
      deleteButton={deleteButton}
      isProfile={isProfile}
      counterparty={counterparty}
    />
  );
};
