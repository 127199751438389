import { GetParksApiParksGetApiResponse } from '@shared/api/services/common/api';
import { VehicleTableType } from '../model/vehicle-table-type';
import { GetHotStorageApiHotStorageGetApiResponse } from '@shared/api/services/telemetry/api';
import { ElectricBusWithTrackerOutput } from '@shared/api/services/electricbus/api';

export const getLngLat = ({
  mapType,
  parks,
  electrobuses,
  data,
  eParkId,
  convoyId,
  eBusId,
}: {
  mapType: VehicleTableType | null;
  parks?: GetParksApiParksGetApiResponse;
  electrobuses?: ElectricBusWithTrackerOutput[];
  data?: GetHotStorageApiHotStorageGetApiResponse;
  eParkId?: string;
  convoyId?: string;
  eBusId?: string;
}): { initialLatitude?: number; initialLongitude?: number } => {
  let firstEbus;
  if (mapType === 'PARK') {
    const currentElectricBusPark = parks?.find(
      ({ id }) => String(id) === String(eParkId)
    );
    const [firstConvoy] = currentElectricBusPark?.groups ?? [];

    firstEbus =
      electrobuses?.find(({ group_id }) => group_id === firstConvoy.id) ?? '';
  }

  if (mapType === 'CONVOY') {
    const currentConvoy = parks
      ?.find(({ id }) => String(id) === String(eParkId))
      ?.groups?.find(({ id }) => String(id) === convoyId);

    firstEbus =
      electrobuses?.find(({ group_id }) => group_id === currentConvoy?.id) ??
      '';
  }

  if (mapType === 'ELECTROBUS') {
    firstEbus = electrobuses?.find(({ id }) => id === eBusId) ?? '';
  }

  const targetGrn = firstEbus?.grn;
  const initialLatitude = data?.find(
    ({ ev }) => ev?.grn === targetGrn
  )?.latitude;
  const initialLongitude = data?.find(
    ({ ev }) => ev?.grn === targetGrn
  )?.longitude;

  return {
    initialLatitude,
    initialLongitude,
  };
};
